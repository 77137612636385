body {
    height: 100vh;
    background-image: linear-gradient(white, #ff9999, #ff4d4d);
}

nav {
    top: 0;
    width: 100%;
    height: 50px;
}

.list {
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.items {
    margin-right: 20px;
    font-size: 20px;
    text-transform: uppercase;
    color: #f1f1f1;
    cursor: pointer;
}

.bground {
    background-image: linear-gradient(white, #ff9999, #ff4d4d);
    flex-flow: column;
    /* background-size: cover; */
}

.btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: #000;
    font-size: 18px;
}

#navA {
    color: maroon;
    text-decoration: none;
}

a {
    cursor: pointer;
    display: inline;
    float: left;
    font-size: 18px;
    font-weight: bold;
    color: red;
}

#navA:hover {
    font-size: 18.5px;
    color: maroon;
}

li {
    margin-left: 15px;
}

@media screen and (max-width: 800px) {
    .mainNav {
        background-color: white;
    }
}