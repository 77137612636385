.mainDiv
{
    height: 25vh;
    width: 25vh;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.50);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    text-decoration: none;
    color: #660000;
}

.mainDiv:hover {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2), 0 12px 24px 0 rgba(0, 0, 0, 0.50); 
    color: #660000;
}

.mainDiv:active{
    background-color: #660000;
    color: white;
}